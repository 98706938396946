// import { Link } from "gatsby"
// import PropTypes from "prop-types"
// import React from "react"
// import ReactDOM from 'react-dom'
// import '../../node_modules/react-modal-video/css/modal-video.min.css';
// import ModalVideo from 'react-modal-video';
// require('./comments.css')


import React, { useState, useEffect, useContext, createContext } from "react";
import styled from "styled-components";
import axios from 'axios';

// import TextArea from "react-textarea-autosize";

import Markdown from "./markdown";
import CommentCard from "./commentcard";
import Button from "./button";

import '../css/recursivecomments.css'

const CommentContext = createContext({});

const fillDigits = (num) => {
  if (num.toString().length == 1) {
    return "0" + num
  }
  return num
}

const replyToComment = (id, postId, content, name, email, rerender) => {

  var registered=window.sessionStorage.getItem("registeredemail");
  var login=window.sessionStorage.getItem("email");

  console.log(registered, login)
  const reply = () => {
  var d = new Date();
  var datestring = fillDigits(d.getFullYear())  
  + "-" 
  + fillDigits(d.getMonth()+1) 
  + "-" 
  + fillDigits(d.getDate()) 
  + "T" 
  + fillDigits(d.getHours()) 
  + ":" 
  + fillDigits(d.getMinutes()) 
  + ":" 
  + fillDigits(d.getSeconds());
  axios.post(`https://dashboard.nativecircle.in/wp-json/wp/v2/comments`,
    {
      "author_email" : email != null ? email : "anonymous@mail.com",
      "author_name" : name != null ? name : "Anonymous",
      "content" : content,
      "date": datestring,
      "post": postId,
      "status": "approved",
      "parent": id
    },
    {headers: {
      "Authorization" : "Basic bmF0aXZlY2lyY2xlOlRtZlYgYkJzNyA1aWt4IHVwRDEgZ05JeiA0Umw1"
    }}
  )
    .then(res => {
      console.log(res.data)
      rerender(true)
      // console.log("COMMM")
      // console.log(res.data[0])
      // console.log(typeof(res.data))
      // let tempcomments = []
      // let commentStructDict = {}
      // if (res.data != null &&  typeof(res.data) == "string" && res.data[0] == "a") {
      //   let tempcommstring = res.data.slice(0,1)
      //   tempcomments = JSON.parse(tempcommstring)
      // } else if (typeof(res.data) == 'object') {
      //   tempcomments = res.data
      // }
      // let tree = unflatten( tempcomments, {id : 0}, [] );
      // this.setState({recComments: tree, allCommentsSize: tempcomments.length})
      // console.log(tree)
    })
    .catch(err=> {
      rerender(false)
    }) 
  }
  return reply;
}


function compare(a1, a2) {
  if (JSON.stringify(a1) === JSON.stringify(a2)) {
    return true;
  }
  return false;
}

function gen_comments(comments, colorindex, path, postId, rerender) {
  return comments != undefined && comments.map((comment, i) => {
    return (
      <Comment
        username={comment.author_name}
        date={comment.date}
        text={comment.content.rendered}
        votes={0}
        colorindex={colorindex}
        key={i}
        path={[...path, i]}
        comments={comment.children}
        postId={postId}
        id={comment.id}
        rerender = {rerender}
      />
    );
  });
}

export function Reply(props) {
  const [text, setText] = useState("");
  const rerender = (resp) => {
    props.rerender(resp)
  }
  return (
    <div {...props}>
      <textarea
        // placeholder="What are your thoughts?"
        // minRows={2}
        value={text}
        onChange={value => {
          setText(value.target.value);
        }}
      />
      <div className="panel">
        <Button
          logic={replyToComment(props.parentId, props.postId, text, null, null, rerender)}
        >COMMENT</Button>
      </div>
    </div>
  );
}

Reply = styled(Reply)`
  border-radius: 8px;
  border: solid 1px #d3d3d3;
  overflow: hidden;

  &.hidden {
    display: none;
  }

  textarea {
    font-family: inherit;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    resize: none;

    background: #fff;
    padding: 12px;
    color: #000;
    border: none;
    max-width: 100%;
    min-width: 100%;
  }

  textarea:focus { 
    outline: none !important;
    border-color: #fff;
  }

  .panel {
    display: flex;
    align-items: center;
    background: #3d4953;
    padding: 8px;

    .comment_as {
      font-size: 14px;
      color: #cccccc;
      margin-right: 8px;

      .username {
        display: inline-block;
        
      }
    }

    ${Button} {
      font-size: 14px;
      margin-left: auto;
    }
  }
`;


function Rating(props) {
  const [count, setCount] = useState(props.votes);
  const [thumbsUp, setThumbsUp] = useState(false);
  const [thumbsDown, setThumbsDown] = useState(false);

  return (
    <div {...props}>
      <button
        className={`material-icons ${thumbsUp ? "selected" : ""}`}
        id="thumbs_up"
        onClick={() => {
          setThumbsUp(!thumbsUp);
          setThumbsDown(false);
        }}
      >
        keyboard_arrow_up
      </button>
      <div
        className={`count ${thumbsUp ? "up" : ""} ${thumbsDown ? "down" : ""}`}
      >
        {thumbsUp ? count + 1 : ""}
        {thumbsDown ? count - 1 : ""}
        {thumbsUp || thumbsDown ? "" : count}
      </div>
      <button
        className={`material-icons ${thumbsDown ? "selected" : ""}`}
        id="thumbs_down"
        onClick={() => {
          setThumbsDown(!thumbsDown);
          setThumbsUp(false);
        }}
      >
        keyboard_arrow_down
      </button>
    </div>
  );
}

Rating = styled(Rating)`
  display: flex;
  flex-direction: column;
  margin-right: 12px;

  .count {
    font-weight: bold;
    text-align: center;
    color: #3d4953;

    &.up {
      color: #4f9eed;
    }

    &.down {
      color: #ed4f4f;
    }
  }

  button#thumbs_up,
  button#thumbs_down {
    border: none;
    background: none;
    cursor: pointer;
    color: #3d4953;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  #thumbs_up.selected {
    color: #4f9eed;
  }

  #thumbs_down.selected {
    color: #ed4f4f;
  }
`;

function Comment(props) {
  const [replying, setReplying] = useContext(CommentContext);
  const [minimized, setMinimized] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    calculateHidden();
  }, [props.path]
  );

  const calculateHidden = async () => {
    if (props.path.length > 2 && props.path.length % 2 === 0) {
      setHidden(true);
    }
    if (props.path[props.path.length - 1] > 3) {
      setHidden(true);
    }
  }

  const rerender = (resp) => {
    if (resp) {
      setReplying([])
    }
    props.rerender()
  }

  return (
    <div {...props}>
      {hidden ? (
        <button
          id="showMore"
          onClick={() => {
            setHidden(false);
          }}
        >
          Show More Replies
        </button>
      ) : (
        <>
          {/* <div id="left" className={minimized ? "hidden" : ""}>
            <Rating votes={props.votes} />
          </div> */}
          <div id="right">
            <div id="top">
              {/* <span
                className="minimize"
                onClick={() => {
                  setMinimized(!minimized);
                }}
              >
                [{minimized ? "+" : "-"}]
              </span> */}
              <span id="username">
                <p className="userpara">{props.username}</p>
              </span>
              <span id="date">
                <p className="userpara">{props.date.split("T")[0] + " " + props.date.split("T")[1]}</p>
              </span>
            </div>
            <div id="content" className={minimized ? "hidden" : ""}>
              {/* <Markdown options={{ forceBlock: true }}>{props.text}</Markdown> */}
              <div dangerouslySetInnerHTML={{__html: props.text}} />
            </div>
            <div id="actions" className={minimized ? "hidden" : ""}>
              <span
                className={`${compare(replying, props.path) ? "selected" : ""}`}
                onClick={() => {
                  if (compare(replying, props.path)) {
                    setReplying([]);
                  } else {
                    setReplying(props.path);
                  }
                }}
              >
                Reply
              </span>
              {/* <span>report</span> */}
            </div>
            <Reply
              postId={props.postId}
              parentId = {props.id}
              name = {null}
              email = {null}
              className={
                compare(replying, props.path) && !minimized ? "" : "hidden"
              }
              rerender = {rerender}
            />
            <div className={`comments ${minimized ? "hidden" : ""}`}>
              {gen_comments(props.comments, props.colorindex + 1, [
                ...props.path
              ], props.postId, props.rerender)}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
//background: ${props => (props.colorindex % 2 === 0 ? "#161C21" : "#13181D")};

Comment = styled(Comment)`
  display: flex;
  text-align: left;
  padding: 16px 16px 16px 12px;
  border: 0.1px solid #d3d3d3;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;


  #showMore {
    background: none;
    border: none;
    color: #53626f;
    cursor: pointer;
    font-size: 13px;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }

  .comments {
    > * {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &.hidden {
      display: none;
    }
  }

  #left {
    text-align: center;
    &.hidden {
      visibility: hidden;
      height: 0;
    }
  }

  #right {
    flex-grow: 1;

    #top {
      flex-direction: column;
      display: flex;
      .minimize {
        cursor: pointer;
        color: #53626f;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
      }

      #username {
        color: #4f9eed;
        .userpara {
          color: #4f9eed;
          font-size: 0.8rem;
          font-weight: 600;
          font-family: 'Merriweather', serif;
          margin-bottom: 0.2rem !important
        }
      }

      #date {
        display: inline-block;
        .userpara {
          color: #272727;
          font-size: 0.7rem;
          margin-bottom: 1rem !important;
          font-family: 'Merriweather', serif;
        }
      }

      > * {
        margin-right: 8px;
      }
    }

    #content {
      color: #cccccc;

      &.hidden {
        display: none;
      }
    }

    #actions {
      color: #53626f;
      margin-top: 1rem;
      margin-bottom: 0.2rem;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

      &.hidden {
        display: none;
      }

      > .selected {
        font-weight: bold;
      }

      > * {
        cursor: pointer;
        margin-right: 8px;
      }
    }
  }

  ${Reply} {
    margin-bottom: 12px;
  }
`;

function RecursiveComments(props) {
  var [replying, setReplying] = useState([]);
  var [comments, setComments] = useState([]);

  useEffect(() => {
    setComments(props.commStruct)
  },[])

  useEffect(() => {
    console.log("RERENDER")
    setComments(props.commStruct)
  },[props.commStruct])

  return (
    <div {...props}>
      {/* <span id="comments">Comments</span>
      <span id="comments_count">(9)</span>
      <Reply /> */}
      <CommentContext.Provider value={[replying, setReplying]}>
        {comments != undefined && gen_comments(comments, 0, [], props.postId, props.rerender)}
      </CommentContext.Provider>
    </div>
  );
}

export default styled(RecursiveComments)`
  min-width: min-content;

  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  #comments,
  #comments_count {
    font-weight: 900;
    font-size: 20px;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 8px;
  }

  #comments {
    color: #ffffff;
  }

  #comments_count {
    color: #53626f;
  }
`;


