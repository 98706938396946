import React from 'react';
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import FormerSuccess from "../components/farmersuccess";
import ModalVideo from 'react-modal-video';
import DonutChart from "react-donut-chart";

import { StaticQuery, graphql } from "gatsby";

import HeaderLight from "../components/headerLight.js";
import Footer from '../components/footer.js'
import '../css/blogpost.css'
import RecursiveComments from '../components/recursivecomments';
import axios from 'axios';
import CommentCard from '../components/commentcard';
import Reply from '../components/reply';

import _ from "lodash"
import GoogleAd from '../components/GoogleAd';
import VerticleAdUnit from '../components/VerticleAdUnit';
import '../components/googleads.css';

import InnerHTML from 'dangerously-set-html-content'

function unflatten ( array, parent, tree ) {
  // console.log(array, parent, tree)
  if (typeof tree == undefined) {
    tree = []
  }
  if (typeof parent == undefined) {
    parent['id'] = 0
  }
      
  let children = _.filter( array, function(child){ 
    // console.log(parent)
    if (child != undefined && parent != undefined) {
      return child.parent == parent.id; 
    }
  });
  if( children != undefined && !_.isEmpty( children )  ){
      if( parent.id == 0 ){
         tree = children;   
      }else{
         parent['children'] = children
      }
      _.each( children, function( child ){ 
        if (child != undefined) {
          // console.log(array, child, tree)
          unflatten( array, child, tree ) 
        }
      } 
      );                    
  }
  
  return tree;
}

class BlogPost extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      posts: [],
      currentPostHTML : '',
      title: '',
      imgsrc: '',
      comments: [],
      recComments: [],
      allCommentsSize: 0,
      postId : null
    };
    this.openModal = this.openModal.bind(this)
    // this.unflatten = this.unflatten.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  


  fetchComments(id) {
    axios.get(`https://dashboard.nativecircle.in/wp-json/wp/v2/comments?post=${id}&per_page=100&page=1&orderby=date&order=desc`)
    .then(res => {
      console.log("COMMM")
      console.log(res.data[0])
      console.log(typeof(res.data))
      let tempcomments = []
      let commentStructDict = {}
      if (res.data != null &&  typeof(res.data) == "string" && res.data[0] == "a") {
        let tempcommstring = res.data.slice(0,1)
        tempcomments = JSON.parse(tempcommstring)
      } else if (typeof(res.data) == 'object') {
        tempcomments = res.data
      }
      let tree = unflatten( tempcomments, {id : 0}, [] );
      this.setState({recComments: tree, allCommentsSize: tempcomments.length})
      console.log(tree)
    })
  }

  componentDidMount() {
    console.log("HI")
      let postsSlugDict = JSON.parse(window.localStorage.getItem("wordpressPostSlugDict"))
      let currentPostSlugArr = this.props.location.pathname.split("/")
      let currentPostSlug = currentPostSlugArr[currentPostSlugArr.length - 1]
      let currentPost = postsSlugDict[currentPostSlug]

    console.log("HI", currentPost)
      this.fetchComments(currentPost.wordpress_id)
      this.setState({currentPostHTML : currentPost.content, title: currentPost.title, postId: currentPost.wordpress_id, imgsrc: currentPost.featured_media.source_url});
      try {
        
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        
      }
        let posts = []
        // let comments = JSON.parse(window.localStorage.getItem("allWordpressComments"))
        // this.setState({comments: comments[currentPost.wordpress_id]})

  }

  render() {
    return (
      <div>
        <HeaderLight pathname="/blog" />
        <div className="col-sm-12 pad0 HowitworksMainDiv1" >
          
          <div style={{display : "flex"}}>
        {/* <VerticleAdUnit pos="left" adslot="5049161406" adwidth="10vw"/> */}

          <div className="container">
            <div className="blogpost col-sm-12 pad0">
            <div className='blogpost-image-container'>
                <img className='blogpost-image' src={this.state.imgsrc} />
              </div>
            <div style={{marginTop: "70px"}}>
                <div>
                  <h5 className="blogheader">{this.state.title}</h5>
                </div>
              </div>
              
              
             <InnerHTML html={this.state.currentPostHTML} />
            
            </div>

            <div style={{height: "10vh"}} />
            <GoogleAd adslot="9141874108" />
          </div>

          {/* <VerticleAdUnit pos="right" adslot="5049161406" adwidth="10vw"/> */}
          </div>
          

          <div className="container" style={{marginTop: "100px"}}>
            <div className="col-sm-12 pad0">
            <h3 className='commentcount'>{`${this.state.allCommentsSize} comments`}</h3>
            <CommentCard>
              <Reply 
                parentId = {null}
                postId = {this.state.postId} 
                name = {null}
                email = {null}
                rerender={() => {this.fetchComments(this.state.postId)}}
              />
            </CommentCard>
            <div style={{marginTop: "60px"}}>
                {
                    this.state.recComments.map(comm => {
                        return <RecursiveComments 
                                    author_name= {comm.author_name}
                                    date={comm.date}
                                    content={comm.content.rendered}
                                    commStruct={[comm]}
                                    postId={this.state.postId}
                                    rerender={() => {this.fetchComments(this.state.postId)}}
                                /> 
                    })
                }
            </div>
            </div>
            <div style={{height: "10vh"}} />
            <GoogleAd adslot="9141874108" />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default BlogPost;