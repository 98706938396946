import React, { useState, useEffect, useContext, createContext } from "react";
import styled from "styled-components";
import axios from 'axios';
import '../css/reply.css'

// import TextArea from "react-textarea-autosize";

import Button from "./button";

const fillDigits = (num) => {
  if (num.toString().length == 1) {
    return "0" + num
  }
  return num
}

const replyToComment = (id, postId, content, name, email, rerender, setText) => {

  

  const reply = () => {
    var registered=window.sessionStorage.getItem("registeredemail");
  var login=window.sessionStorage.getItem("email");

  console.log(registered, login)

  var dataobject={
    email:login

  }
   axios.post("https://dashboard.nativecircle.in/wp-json/api/login",dataobject)
    .then(response=>{
      console.log(response.data.message)
      if(response.data.status==200) {
          var payDetails=response.data.message[0]
          // var phone,pincode
          // payDetails.phone==0 ? phone="" : phone=payDetails.phone
          // payDetails.pincode==0 ? pincode="" :pincode=payDetails.pincode
          // this.setState({
          //   pro_fullName:payDetails.full_name,
          //   pro_phone:phone,
          //   pro_email:payDetails.email,
          //   pro_pincode:pincode,
          //   pro_building:payDetails.building,
          //   pro_landmark:payDetails.landmark,
          //   pro_area:payDetails.area,
          //   pro_state:payDetails.state,
          //   pro_town:payDetails.town
        
        
          //        })
         console.log(payDetails)
         postReply(payDetails.email, payDetails.full_name, content, postId, id, rerender, setText)
      } else {
        postReply(null, null, content, postId, id, rerender, setText)
      }
      
    })
    .catch(err => {
      console.log(err);
      postReply(null, null, content, postId, id, rerender, setText)
    });
  
  }
  return reply;
}


const postReply = (email, name, content, postId, id, rerender, setText) => {
  var d = new Date();
  var datestring = fillDigits(d.getFullYear())  
  + "-" 
  + fillDigits(d.getMonth()+1) 
  + "-" 
  + fillDigits(d.getDate()) 
  + "T" 
  + fillDigits(d.getHours()) 
  + ":" 
  + fillDigits(d.getMinutes()) 
  + ":" 
  + fillDigits(d.getSeconds());
  axios.post(`https://dashboard.nativecircle.in/wp-json/wp/v2/comments`,
    {
      "author_email" : email != null ? email : "anonymous@mail.com",
      "author_name" : name != null ? name : "Anonymous",
      "content" : content,
      "date": datestring,
      "post": postId,
      "status": "approved",
      "parent": id
    },
    {headers: {
      "Authorization" : "Basic bmF0aXZlY2lyY2xlOlRtZlYgYkJzNyA1aWt4IHVwRDEgZ05JeiA0Umw1"
    }}
  )
    .then(res => {
      console.log(res.data)
      rerender()
      setText()
    })
}

function Reply(props) {
    const [text, setText] = useState("");
    return (
      <div {...props}>
        <textarea
          // placeholder="What are your thoughts?"
          // minRows={2}
          value={text}
          placeholder="Please provide your thoughts on this post"
          onChange={value => {
            setText(value.target.value);
          }}
        />
        <div className="panel">
          
          <Button 
            logic={replyToComment(props.parentId, props.postId, text, props.name, props.email, props.rerender, () => {setText("")})}
          >COMMENT</Button>
        </div>
      </div>
    );
  }
  
  export default styled(Reply)`
    border-radius: 8px;
    border: solid 1px #3d4953;
    overflow: hidden;
  
    &.hidden {
      display: none;
    }
  
    textarea {
      font-family: inherit;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      resize: none;

      background: #fff;
      padding: 12px;
      color: #000;
      border: none;
      max-width: 100%;
      min-width: 100%;
    }
  
    textarea:focus { 
      outline: none !important;
      border-color: #fff;
    }
  
    .panel {
      display: flex;
    align-items: center;
    background: #3d4953;
    padding: 8px;

    .comment_as {
      font-size: 14px;
      color: #cccccc;
      margin-right: 8px;

      .username {
        display: inline-block;
        .userpara {
          color: #4f9eed;
          font-size: 0.9rem;
        }
      }
    }
  
      ${Button} {
        font-size: 14px;
        margin-left: auto;
      }
    }
  `;